<template>
  <section>
    <b-row>
      <b-col sm="12" class="text-right">
        <button type="button" class="btn btn-primary" @click="clickAddCredit">เติม เครดิต</button>
      </b-col>
    </b-row>
    <b-card title="ข้อมูล ซื้อรูปภาพ" class="mt-1">
      <b-overlay :show="tableLoading">
        <TableDataWidget
          ref="SupportCustomersClickViewTableImage"
          :columns="_columnsPurchaseImage"
          :items="results.purchaseImage"
        />
        <!-- <pre>{{ results.purchaseImage }}</pre> -->
      </b-overlay>
    </b-card>
    <b-card title="ข้อมูล ธุรกรรม">
      <b-overlay :show="tableLoading">
        <TableDataWidget
          ref="SupportCustomersClickViewTable"
          :columns="_columnsPurchaseStripe"
          :items="results.purchaseStripe"
          view-able
          @clickView="seeMoreCreditTransaction"
        />
        <!-- <pre>{{ results.purchaseStripe }}</pre> -->
      </b-overlay>
    </b-card>

    <b-modal
      ref="refModalAddCredit"
      title="เติมเครดิต"
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      ok-title="เติม"
      cancel-title="ยกเลิก"
      :ok-disabled="modalAddCreditLoading"
      :cancel-disabled="modalAddCreditLoading"
      @ok="confirmAddCredit"
      @cancel="clearFormAddCredit"
    >
      <b-overlay :show="modalAddCreditLoading">
        <b-form-group
          label="จำนวนเครดิต"
          label-for="amount-add-credit-input"
          description="สามารถระบุจำนวนติดลบได้ ซึ่งจะเป็นการไปลดเครดิตแทน"
        >
          <b-form-input
            id="amount-add-credit-input"
            v-model="_creditAmount"
            placeholder="ระบุเครดิตที่ต้องการเติมหรือลด"
          />
        </b-form-group>

        <b-form-group label="หมายเหตุ" label-for="note-add-credit-input">
          <b-form-textarea id="note-add-credit-input" v-model="formAddCredit.note" rows="3" />
        </b-form-group>
      </b-overlay>
    </b-modal>
  </section>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    ToastificationContent,
  },
  data() {
    return {
      results: {
        purchaseStripe: [],
        purchaseImage: [],
      },
      formAddCredit: {
        amount: 0,
        note: '',
      },
      modalAddCreditLoading: false,
      tableLoading: false,
    }
  },
  computed: {
    _columnsPurchaseImage() {
      return [
        {
          label: 'วันที่ (วัน-เดือน-ปี)',
          field: row => this.$date(row.created_at).format('D-M-YYYY HH:mm:ss'),
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          label: 'รูป',
          field: 'image_data_transaction_data.topic_image_data.name',
          type: 'string',
        },
        {
          label: 'ยอดเดิม',
          field: 'credit_balance',
          type: 'number',
        },
        {
          label: 'ยอดหัก',
          field: 'credit_amount',
          type: 'number',
        },
        {
          label: 'ยอดคงเหลือ',
          field: 'credit_total',
          type: 'number',
        },
      ]
    },
    _columnsPurchaseStripe() {
      return [
        {
          label: 'วันที่ (วัน-เดือน-ปี)',
          field: row => this.$date(row.created_at).format('D-M-YYYY HH:mm:ss'),
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          label: 'รหัสอ้างอิง',
          field: 'id',
          type: 'string',
        },
        {
          label: 'รหัสอ้างอิงภายนอก',
          field: 'outside_id',
          type: 'string',
        },
        {
          label: 'ยอดเดิม',
          field: 'credit_balance',
          type: 'number',
        },
        {
          label: 'ยอดเติม',
          field: 'credit_amount',
          type: 'number',
        },
        {
          label: 'ยอดคงเหลือ',
          field: 'credit_total',
          type: 'number',
        },
        {
          label: 'สถานะ',
          field: 'status_text_preview',
          type: 'string',
        },
        {
          label: '',
          field: 'action',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ]
    },
    _creditAmount: {
      get() {
        return this.formatNumberToCurrency(this.formAddCredit.amount ?? 0)
      },
      set(val) {
        // if (!val) {
        //   this.form.credit = NaN
        //   return
        // }

        const v = `${val}`.replace(/,/g, '')
        if (!Number(v)) {
          this.formAddCredit.amount = 0
        } else {
          this.formAddCredit.amount = Number(v)
        }
      },
    },
  },
  async created() {
    this.queryDataTables()
  },
  methods: {
    async queryDataTables() {
      this.tableLoading = true
      this.results = await this.api.get(`/api/supports/client-transaction-data/${this.$route.params.clientId}`)
      this.tableLoading = false
    },
    clearFormAddCredit() {
      this.formAddCredit = {
        amount: 0,
        note: '',
      }
    },
    clickAddCredit() {
      // this.$router.push({ name: 'supports-add-credit', params: { ...this.results.clientInfo } })

      this.$refs.refModalAddCredit.show()
    },
    async confirmAddCredit(bvModalEvt) {
      bvModalEvt.preventDefault()

      // console.log('[SupportCustomersClickView] confirmAddCredit', this.formAddCredit)

      if (this.formAddCredit.amount === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'โปรดระบุจำนวนเครดิตที่ต้องการเพิ่มหรือลด',
            icon: 'BellIcon',
            variant: 'danger',
          },
        })
        return
      }

      this.modalAddCreditLoading = true
      // await new Promise(r => setTimeout(r, 1000))
      const resp = await this.api.post('api/supports/credit/exchange-credit', {
        ...this.formAddCredit,
        user_id: `${this.$route.params.clientId}`,
      })
      // console.log('resp', resp)
      this.clearFormAddCredit()
      this.modalAddCreditLoading = false
      this.$refs.refModalAddCredit.hide()
      this.queryDataTables()
    },
    seeMoreCreditTransaction(row) {
      // console.log('[seeMoreCreditTransaction] row', row)
      this.$router.push({
        name: 'supports-customers-client-credit-transaction',
        params: { clientId: this.$route.params.clientId, creditTransactionId: row.id, row },
      })
    },
  },
}
</script>
